import Vue from "vue";
import VueRouter from "vue-router";
import Store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/landing",
  },
  /**************** 登入 ****************/
  {
    path: "/landing",
    name: "登入/註冊",
    component: () => import("../views/Landing.vue"),
    meta: {
      bgc: "linear-gradient(180deg, #ECFBF7 -9.88%, #FBFEFF 110.6%)",
      header: { align: "left" },
    },
  },
  {
    path: "/login",
    name: "登入",
    component: () => import("../views/Login.vue"),
    meta: {
      bgc: "linear-gradient(180deg, #ECFBF7 -9.88%, #FBFEFF 110.6%)",
      header: { align: "left", back: true },
    },
  },
  {
    path: "/register",
    name: "註冊",
    component: () => import("../views/Register.vue"),
    meta: {
      bgc: "linear-gradient(180deg, #ECFBF7 -9.88%, #FBFEFF 110.6%)",

      header: { align: "left", back: true },
    },
  },
  {
    path: "/newPassword",
    name: "設定密碼",
    component: () => import("../views/NewPassword.vue"),
    meta: {
      bgc: "linear-gradient(180deg, #ECFBF7 -9.88%, #FBFEFF 110.6%)",

      header: { align: "left", back: true },
    },
  },
  {
    path: "/forgetPassword",
    name: "忘記密碼",
    component: () => import("../views/ForgetPassword.vue"),
    meta: {
      bgc: "linear-gradient(180deg, #ECFBF7 -9.88%, #FBFEFF 110.6%)",

      header: { align: "left", back: true },
    },
  },
  {
    path: "/resetPassword",
    name: "重設密碼",
    component: () => import("../views/ResetPassword.vue"),
    meta: {
      bgc: "linear-gradient(180deg, #ECFBF7 -9.88%, #FBFEFF 110.6%)",

      header: { align: "left", back: true },
    },
  },
  {
    path: "/profileName",
    name: "歡迎設定名稱",
    component: () => import("../views/ProfileName.vue"),
    meta: {
      bgc: `url(${require("../assets/image/profile-bg.png")}) center`,
    },
  },
  {
    path: "/profileBasic",
    name: "歡迎設定基本資料",
    component: () => import("../views/ProfileBasic.vue"),
    meta: {
      bgc: "linear-gradient(180deg, #ECFBF7 -9.88%, #FBFEFF 110.6%)",
    },
  },
  {
    path: "/start",
    name: "開始頁面",
    component: () => import("../views/Start.vue"),
    meta: {
      bgc: `url(${require("../assets/image/start-bg.png")}) no-repeat center / cover`,
    },
  },
  /**************** 商店 ****************/
  {
    path: "/storeHome",
    name: "商店",
    component: () => import("../views/Store/StoreHome.vue"),
    meta: {
      bgc: "linear-gradient(180deg, #FFEEC1 0%, #FFD15A 100%)",
    },
  },
  {
    path: "/redeem",
    name: "兌換內容",
    component: () => import("../views/Store/Redeem.vue"),
    meta: {
      bgc: "#F5F5F5",
      header: { align: "center", back: true },
    },
  },
  {
    path: "/voucherList",
    name: "我的兌換券",
    component: () => import("../views/Store/VoucherList.vue"),
    meta: {
      bgc: "#FFFFFF",
      header: { align: "center", back: true, bgc: "#FFAB64", color: "#fff" },
    },
  },
  /**************** 個人設定 ****************/
  {
    path: "/shape",
    name: "我的體態",
    component: () => import("../views/UserSetting/Shape.vue"),
    meta: {
      bgc: "#F4F4F4",
    },
  },
  {
    path: "/account",
    name: "我的帳號",
    component: () => import("../views/UserSetting/Account.vue"),
    meta: {
      bgc: "#F4F4F4",
    },
  },
  {
    path: "/password",
    name: "修改密碼",
    component: () => import("../views/UserSetting/Password.vue"),
    meta: {
      bgc: "#F4F4F4",
    },
  },
  {
    path: "/authorize",
    name: "授權管理",
    component: () => import("../views/UserSetting/Authorize.vue"),
    meta: {
      bgc: "#F4F4F4",
    },
  },
  /**************** 紀錄 ****************/
  {
    path: "/BM",
    name: "排便紀錄",
    component: () => import("../views/Record/BM.vue"),
    meta: {
      bgc: "#FFF",
    },
  },
  {
    path: "/mood",
    name: "心情紀錄",
    component: () => import("../views/Record/Mood.vue"),
    meta: {
      bgc: "#FFF",
    },
  },
  {
    path: "/exercise",
    name: "運動紀錄",
    component: () => import("../views/Record/Exercise.vue"),
    meta: {
      bgc: "#FFF",
    },
  },
  {
    path: "/sleep",
    name: "睡眠紀錄",
    component: () => import("../views/Record/Sleep.vue"),
    meta: {
      bgc: "#FFF",
    },
  },
  {
    path: "/bloodPressure",
    name: "血壓紀錄",
    component: () => import("../views/Record/BloodPressure.vue"),
    meta: {
      bgc: "#FFF",
    },
  },
  {
    path: "/water",
    name: "喝水紀錄",
    component: () => import("../views/Record/Water.vue"),
    meta: {
      bgc: "#FFF",
    },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.query.access_token) {
    Store.dispatch("clearUser");
    Store.dispatch("setToken", { access_token: to.query.access_token });
  }
  next();
});

export default router;
