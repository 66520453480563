export function maping(array) {
  const map = {};

  array.forEach((i) => {
    map[i.value] = i.label;
  });

  return map;
}

export function checkId(id) {
  var tab = "ABCDEFGHJKLMNPQRSTUVXYWZIO";
  var A1 = new Array(
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    2,
    2,
    2,
    2,
    2,
    2,
    2,
    2,
    2,
    2,
    3,
    3,
    3,
    3,
    3,
    3
  );
  var A2 = new Array(
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    0,
    1,
    2,
    3,
    4,
    5
  );
  var Mx = new Array(9, 8, 7, 6, 5, 4, 3, 2, 1, 1);
  if (!id || id.length != 10) return false;
  var i = tab.indexOf(id.charAt(0));
  if (i == -1) return false;
  var sum = A1[i] + A2[i] * 9;

  for (i = 1; i < 10; i++) {
    var v = parseInt(id.charAt(i));
    if (isNaN(v)) return false;
    sum = sum + v * Mx[i];
  }
  if (sum % 10 != 0) return false;
  return true;
}
